
$(document).ready(function() {
    $(".down-arrow-icon").click(function() {
        var DcOffset = $('.aboutus-section').offset().top - ($('.topbar').outerHeight() + 60);
        $('body,html').animate({ scrollTop: DcOffset },1000);
    });
});



if ($("body").hasClass("blog")) {

    // Dont fire

} else if($("body").hasClass("search")){

    // Dont fire

} else {

    var featuredSlider = $('.developments-list-slider').lightSlider({
        item: 1,
        pager: false,
        loop: false,
        controls: false,
    })
    $('.prev-arrow').on('click', function () {
        featuredSlider.goToPrevSlide();
    });
    $('.next-arrow').on('click', function () {
        featuredSlider.goToNextSlide();
    });

    var lastScrollTop = 0;
    var topPosition2 = 0;
    $(window).scroll(function () {
        var st = jQuery(this).scrollTop();
        if (st > lastScrollTop) {
            var scroll = 1;
        } else {
            var scroll = 0;
        }
        lastScrollTop = st;
        if ($(window).width() > 767) {
            $(".development-imgbox").each(function () {
                topPosition2 = $(this).attr('topPosition2');
                topPosition2 = parseFloat(topPosition2, 5);
                var top_of_element = $(".development-imgbox").offset().top;
                var bottom_of_element = $(".development-imgbox").offset().top + $(".development-imgbox").outerHeight();
                var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
                var top_of_screen = $(window).scrollTop();

                if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)) {
                    if (scroll == 1) {
                        topPosition2 = topPosition2 - 1;
                        if (topPosition2 < -80) {
                            topPosition2 = -80;
                        }
                        $('.development-imgbox').css({'top': topPosition2 + 'px'});
                        $('.development-imgbox').attr('topPosition2', topPosition2);
                    } else {
                        topPosition2 = topPosition2 + 1;
                        if (topPosition2 > 80) {
                            topPosition2 = 80;
                        }
                        $('.development-imgbox').css({'top': topPosition2 + 'px'});
                        $('.development-imgbox').attr('topPosition2', topPosition2);
                    }
                } else {
                    // the element is not visible, do something else
                }
            });
        }
    });


}