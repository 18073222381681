
/** topbar fix JS **/
$(window).scroll(function() {
	if($(window).scrollTop() > 0){
		$(".topbar").addClass('topbar-fix');
		$('body').css('padding-top', '50px');
	} else {
		$(".topbar").removeClass('topbar-fix');
		$('body').css('padding-top', '0px');
	}
});
$('.menu-icon').click(function() {
	$(this).css('display', 'none');
	$('.close-icon').css('display', 'inline-block');
	$("body").addClass('menu_open');
});
$('.close-icon').click(function() {
	$(this).css('display', 'none');
	$('.menu-icon').css('display', 'inline-block');
	$("body").removeClass('menu_open');
	$('.sub-link').removeClass('active-menu');
	$('.menu-link-li').removeClass('open');
});
$('.searchpopup').on('shown.bs.modal', function (e) {
	$(".search .form-control").focus();
});

$(".menu-link-li .menu-arrow").each(function() {
	$(this).bind("mouseenter click", function() {
		$('.sub-link').removeClass('active-menu');
		$('.menu-link-li').removeClass('open');
		var dataID = $(this).prev('.menu-link').attr('data-id');
		$('#'+dataID).addClass('active-menu');
		$(this).parents('.menu-link-li').toggleClass('open');
	});
});
if ($(window).width() > 1024) {
	$(".menu-list-head").each(function() {
		$(this).bind("mouseenter", function() {
			$('.sub-link').removeClass('active-menu');
			$('.menu-link-li').removeClass('open');
			var dataID = $(this).find('.menu-link').attr('data-id');
			$('#'+dataID).addClass('active-menu');
		});
	});
}
$(window).resize(function(event) {
	$('.menu-link-li').removeClass('open');
});

function getMobileOperatingSystem() {
	var userAgent = navigator.userAgent || navigator.vendor || window.opera;
	if (/iPad/.test(userAgent) && !window.MSStream && $(window).width() >= 1024) {
		return "iOS";
		$('.menu-list-head').unbind("click mouseenter");
	}
	return "unknown";
}getMobileOperatingSystem();